<template>
  <div class="ficheConfig style-box-setting">
    <div class="style-box-setting-header">
      <div class="title-header">
        Gestion des Fiches
      </div>
    </div>
    <v-divider></v-divider>
    <div class="tableFiche mr-2 mb-2" v-if="getFicheStandard">
      <v-data-table
        :headers="fields_1"
        :items="table_1"
        hide-default-footer
        hide-action
        disable-filtering
        disable-sort
        disable-pagination
        class="table-fichier"
        item-class="text-center"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th :colspan="headers.length">
                <span class="titleFiche"> FICHE STANDARD : TYPE CHAUFFAGE</span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr>
              <td>{{ items[0].zone }}</td>
              <td>
                <EditableInput
                  :editable="checkPermission('GFTHMO')"
                  champName="h1Electrique"
                  :item="getFicheStandard"
                  :value="getFicheStandard.h1Electrique"
                  type="text"
                  :defaultColor="true"
                  :updateFunction="editFicheStandard"
                  className="editable-input-fiche"
                />
              </td>
              <td>
                <EditableInput
                  :editable="checkPermission('GFTHMO')"
                  champName="h1Combustible"
                  :item="getFicheStandard"
                  :value="getFicheStandard.h1Combustible"
                  type="text"
                  :defaultColor="true"
                  :updateFunction="editFicheStandard"
                  className="editable-input-fiche"
                />
              </td>
            </tr>
            <tr>
              <td>{{ items[1].zone }}</td>
              <td>
                <EditableInput
                  :editable="checkPermission('GFTHMO')"
                  champName="h2Electrique"
                  :item="getFicheStandard"
                  :value="getFicheStandard.h2Electrique"
                  type="text"
                  :defaultColor="true"
                  :updateFunction="editFicheStandard"
                  className="editable-input-fiche"
                />
              </td>
              <td>
                <EditableInput
                  :editable="checkPermission('GFTHMO')"
                  champName="h2Combustible"
                  :item="getFicheStandard"
                  :value="getFicheStandard.h2Combustible"
                  type="text"
                  :defaultColor="true"
                  :updateFunction="editFicheStandard"
                  className="editable-input-fiche"
                />
              </td>
            </tr>
            <tr>
              <td>{{ items[2].zone }}</td>
              <td>
                <EditableInput
                  :editable="checkPermission('GFTHMO')"
                  champName="h3Electrique"
                  :item="getFicheStandard"
                  :value="getFicheStandard.h3Electrique"
                  type="text"
                  :defaultColor="true"
                  :updateFunction="editFicheStandard"
                  className="editable-input-fiche"
                />
              </td>
              <td>
                <EditableInput
                  :editable="checkPermission('GFTHMO')"
                  champName="h3Combustible"
                  :item="getFicheStandard"
                  :value="getFicheStandard.h3Combustible"
                  type="text"
                  :defaultColor="true"
                  :updateFunction="editFicheStandard"
                  className="editable-input-fiche"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <div class="tableFiche" v-if="getFichePouce">
      <v-data-table
        :headers="fields_2"
        :items="table_2"
        hide-default-footer
        hide-action
        disable-filtering
        disable-sort
        disable-pagination
        class="table-fichier"
        item-class="text-center"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th :colspan="headers.length">
                <span class="titleFiche"> FICHE COUP DE POUCE</span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:body="{}">
          <tbody>
            <tr>
              <td>
                <EditableInput
                  :editable="checkPermission('GFTHMO')"
                  champName="Classique"
                  :item="getFichePouce"
                  :value="getFichePouce.Classique"
                  type="text"
                  :defaultColor="true"
                  :updateFunction="editFichePouce"
                  className="editable-input-fiche"
                />
              </td>
              <td>
                <EditableInput
                  :editable="checkPermission('GFTHMO')"
                  champName="simple_ou_grand"
                  :item="getFichePouce"
                  :value="getFichePouce.simple_ou_grand"
                  type="text"
                  :defaultColor="true"
                  :updateFunction="editFichePouce"
                  className="editable-input-fiche"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      table_1: [
        {
          align: 'start',
          zone: 'H1'
        },
        { zone: 'H2' },
        { zone: 'H3' }
      ],
      fields_1: [
        {
          text: '',
          align: 'start'
        },
        { text: 'Electrique' },
        { text: 'Combustible' }
      ],
      table_2: [],
      fields_2: [{ text: 'Classique' }, { text: 'Simple ou grand' }]
    };
  },
  methods: {
    ...mapActions([
      'fetchFicheStandard',
      'fetchFichePouce',
      'editFicheStandard',
      'editFichePouce'
    ])
  },
  computed: {
    ...mapGetters(['getFicheStandard', 'getFichePouce', 'checkPermission'])
  },
  components: {
    EditableInput: () => import('../component/EditableInput.vue')
  },
  mounted() {
    this.fetchFicheStandard();
    this.fetchFichePouce();
  }
};
</script>
<style lang="scss" scoped>
.ficheConfig {
  display: block;
  padding: 10px;
  margin: 0px;
  min-height: 100%;
  width: 100%;
  overflow-y: hidden;
  .tableFiche {
    display: inline;
  }
}
.ligne-box-setting-header {
  margin-bottom: 17px;
  width: 100% !important;
  margin-top: 9px;
  border-bottom: 1px solid #cccccc73;
}

.table-fichier {
  .titleFiche {
    color: #704ad1;
    font-weight: 600 !important;
    font-family: 'Montserrat', sans-serif;
  }
  padding-top: 10px;
  thead {
    tr {
      background-color: #fff;
      text-align: center !important;
      th {
        border: 1px solid #e0e0e0;
        font-size: 13px !important;
        text-align: center !important;
        height: 35px !important;
        span {
          font-size: 12px;
          color: #2a2a2a;
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
          white-space: nowrap;
        }
      }
    }
  }
  tbody {
    tr {
      border: 1px solid #e0e0e0;
      background-color: #fff;
      text-align: center !important;
      td {
        border: 1px solid #e0e0e0;
        font-size: 13px !important;
        text-align: center !important;
        height: 35px !important;
      }
      &:hover {
        border: 1px solid #e0e0e0 !important;
        background-color: #fff !important;
        text-align: center !important;
      }
    }
  }
}
</style>
<style lang="scss">
.table-fichier {
  .v-data-table-header {
    tr {
      background-color: #fff;
      text-align: center !important;
      th {
        border: 1px solid #e0e0e0;
        font-size: 13px !important;
        text-align: center !important;
        span {
          font-size: 12px;
          color: #2a2a2a;
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
